import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import {
  Tooltip,
  Icon,
  Card,
  Stack,
  Button,
  Container,
  Typography,
  ListItemIcon,
  Box,
} from '@mui/material';


import QrCodeScannerRoundedIcon from '@mui/icons-material/QrCodeScannerRounded';
import {dateToShowFormat, isBeforeToday} from "utils/ext"
import {isAdmin} from '../../../utils/session'

function NameCell({name}) {

  let display = name;
  if(name.length > 20) {
      display = name.substring(0, 20)+"..."
  }

  return (
    <SuiBox >
      <SuiTypography ms={2} variant="body" fontWeight="large" textColor="text">
        {display} 
      </SuiTypography>
    </SuiBox>
  );
}

function AgendaCell({agenda}) {
  let display = agenda;
  if(agenda && agenda.length > 15) {
      display = agenda.substring(0, 15)+"..."
  }

  return (
    <SuiBox >
      <SuiTypography ms={2} variant="body" fontWeight="large" textColor="text">
        {display} 
      </SuiTypography>
    </SuiBox>
  );
}

function TimeCell({ date, zone, duration }) {
  return (
    <SuiBox>
      <SuiBox display="flex" flexDirection="row">
        <Icon  mr={2}  className="material-icons-round" color="info">today</Icon>
        <SuiTypography  ml={1}   variant="body" fontWeight="large" textColor="text">
          {dateToShowFormat(date, zone)}
        </SuiTypography>
      </SuiBox>
      <SuiBox display="flex" flexDirection="row">
        <Icon  mr={2} className="material-icons-round" color="info">schedule</Icon>
        <SuiTypography ml={1}  variant="body" fontWeight="large" textColor="text">
        {duration} mins 
        </SuiTypography>
      </SuiBox>      
    </SuiBox>
  );
}


const ActionCell2 = ({meeting, onDetails, onViewQrCode, onDelete}) => {
  if(!isAdmin()) {
    return (
      <>
      <Tooltip title="Details">
        <Button onClick={() => onDetails(meeting)}>
           <ListItemIcon style={{ justifyContent: 'center' }}>
            <Icon className="material-icons-round">info</Icon>
           </ListItemIcon>
         </Button>
       </Tooltip>
       <Tooltip title="QR">
        <Button onClick={() => onViewQrCode(meeting)}>
           <ListItemIcon style={{ justifyContent: 'center' }}>
           <Icon className="material-icons-round"><QrCodeScannerRoundedIcon /></Icon>
           </ListItemIcon>
         </Button>
       </Tooltip>   
     </>
    )
  }

  return (
     <>
    <Tooltip title="Details">
      <Button onClick={() => onDetails(meeting)}>
         <ListItemIcon style={{ justifyContent: 'center' }}>
          <Icon className="material-icons-round">info</Icon>
         </ListItemIcon>
       </Button>
     </Tooltip>
     <Tooltip title="QR">
      <Button onClick={() => onViewQrCode(meeting)}>
         <ListItemIcon style={{ justifyContent: 'center' }}>
         <Icon className="material-icons-round"><QrCodeScannerRoundedIcon /></Icon>
         </ListItemIcon>
       </Button>
     </Tooltip>
     <Tooltip title="Delete" >
       <Button onClick={() => onDelete(meeting)}>
         <ListItemIcon style={{ justifyContent: 'center' }}>
          <Icon className="material-icons-round">delete</Icon>
         </ListItemIcon>
       </Button>
     </Tooltip>      
   </>
  )
 
}

function ActionCell({meeting, onDetails, onViewQrCode, onDelete}) {
  // console.log(`meeting ${meetingId}`)

  if(!isAdmin()) {
    return (
      <SuiBox display="flex" flexDirection="row">
        <SuiButton variant="caption" fontWeight="medium" textColor="text" onClick={() => onDetails(meeting)}>
          <Icon className="material-icons-round">info</Icon>
          <SuiTypography margin="5px"  variant="body" fontWeight="medium" textColor="text">
              Details
          </SuiTypography>
        </SuiButton>      
        <SuiButton variant="caption" fontWeight="medium" textColor="text"  onClick={() => onViewQrCode(meeting)}>
          <Icon className="material-icons-round"><QrCodeScannerRoundedIcon /></Icon>
            <SuiTypography margin="5px" variant="caption" fontWeight="medium" textColor="text">
              QR 
            </SuiTypography>
        </SuiButton>     
    </SuiBox>
    )
  }

  return (
    <SuiBox display="flex" flexDirection="row">
        <SuiButton  variant="body" fontWeight="medium" textColor="text" onClick={() => onDetails(meeting)}>
          <Icon className="material-icons-round">info</Icon>
          <SuiTypography margin="5px"  variant="body" fontWeight="medium" textColor="text">
              Details
          </SuiTypography>
        </SuiButton>        
        <SuiButton variant="caption" fontWeight="medium" textColor="text"  onClick={() => onViewQrCode(meeting)}>
          <Icon className="material-icons-round"><QrCodeScannerRoundedIcon /></Icon>
            <SuiTypography margin="5px" variant="caption" fontWeight="medium" textColor="text">
              QR 
            </SuiTypography>
        </SuiButton>        
        <SuiButton  variant="body" fontWeight="medium" textColor="text" onClick={() => onDelete(meeting)}>
          <Icon className="material-icons-round">delete</Icon>
          <SuiTypography margin="5px"  variant="body" fontWeight="medium" textColor="text">
              Delete
          </SuiTypography>
        </SuiButton>
    </SuiBox>
  )
}

function ActionRecurrenceCell({index, meeting, occurrence, onRecurringDetails, onViewQrCode, onDelete}) {
  // console.log(`meeting ${meetingId}`)

  if(!isAdmin()) {
    return (
      <SuiBox display="flex" flexDirection="row">
        <SuiButton variant="caption" fontWeight="medium" textColor="text" onClick={() =>  onRecurringDetails(meeting, index, occurrence)}>
          <Icon className="material-icons-round">info</Icon>
          <SuiTypography margin="5px"  variant="body" fontWeight="medium" textColor="text">
              Details
          </SuiTypography>
        </SuiButton>      
        <SuiButton variant="caption" fontWeight="medium" textColor="text"  onClick={() => onViewQrCode(meeting)}>
          <Icon className="material-icons-round"><QrCodeScannerRoundedIcon /></Icon>
            <SuiTypography margin="5px" variant="caption" fontWeight="medium" textColor="text">
              QR 
            </SuiTypography>
        </SuiButton>     
    </SuiBox>
    )
  }

  return (
    <SuiBox display="flex" flexDirection="row">
        <SuiButton  variant="body" fontWeight="medium" textColor="text" onClick={() => onRecurringDetails(meeting)}>
          <Icon className="material-icons-round">info</Icon>
          <SuiTypography margin="5px"  variant="body" fontWeight="medium" textColor="text">
              Details
          </SuiTypography>
        </SuiButton>        
        <SuiButton variant="caption" fontWeight="medium" textColor="text"  onClick={() => onViewQrCode(meeting)}>
          <Icon className="material-icons-round"><QrCodeScannerRoundedIcon /></Icon>
            <SuiTypography margin="5px" variant="caption" fontWeight="medium" textColor="text">
              QR 
            </SuiTypography>
        </SuiButton>        
        <SuiButton  variant="body" fontWeight="medium" textColor="text" onClick={() => onDelete(meeting)}>
          <Icon className="material-icons-round">delete</Icon>
          <SuiTypography margin="5px"  variant="body" fontWeight="medium" textColor="text">
              Delete
          </SuiTypography>
        </SuiButton>
    </SuiBox>
  )
}

export function getMeetingRows(data, onViewQr, onDetails, onDelete){

  const timeNow = new Date().getTime();
  const rows = [];
  let index = 0;
  data?.map((meeting) =>
  {
      // console.log('meeting', meeting);
      console.log('meeting', timeNow, meeting.timestamp)
      // if (timeNow <= meeting.timestamp) {
        rows.push(
          {
            id: index+=1,
            name: meeting.topic,
            agenda: meeting.agenda,
            time: `${dateToShowFormat(meeting.start_time, meeting.timezone)}` ,
            duration: `${meeting.duration} mins` ,
            action: <ActionCell2 meeting={meeting} onDetails={onDetails} onViewQrCode={onViewQr} onDelete={onDelete} />,
          }
        )
      // }
     
    }
  );

  return rows;
}

export default getMeetingRows();

export function getColoumns() {
  return [
    { name: "name", align: "left" },
    { name: "agenda", align: "left" },
    { name: "time", align: "left" },
    { name: "action", align: "center" },
  ]
}


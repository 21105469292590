// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useState, useEffect, useRef } from "react";
import { useHistory, Redirect, Link } from 'react-router-dom'
import Select, {StylesConfig} from 'react-select'
import makeAnimated from 'react-select/animated';
import moment from 'moment';

import {Alert, AlertTitle, Stack, TextField} from "@mui/material";
import {progressDialog, RecurrenceModal, RecurrenceModal2} from "utils/diloag"
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import ArrowBack from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';

import { ReactMultiEmail, isEmail } from 'react-multi-email';
import './style.css';

// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";
import {isAuthenticated, getUser, getUserEmail} from "utils/session" 


import Footer from "examples/Footer";
import Table from "examples/Table";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import Divider from "@mui/material/Divider";
import {getDateAfterMonth, getDateNow, getTimeNow, getTimeZone, dateToServerFormat, getDayOfMonth} from "utils/ext"
import {meetingsApi, apiPostSecure, membersApi, apiCallSecureGet} from "utils/api"
import { stat } from "fs";

function showErrorAlert(msg) {
  let view = null;

  if(msg) {
    view = (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
         {msg}
      </Alert>
    )
  }

  return view;
}

function showSuccessAlert(msg) {
  let view = null;

  if(msg) {
    view = (
      <Alert severity="success">
        <AlertTitle>Success!</AlertTitle>
         {msg}
      </Alert>
    )
  }

  return view;
}

function generatePassword(length) {
  let result           = '';
  const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  // console.log('password', result);
  return result;
}

const getRecurrenceMeeting = (state) => {

  console.log('getRecurrenceMeeting', 'state', state);
  if(state == null) {
    return null;
  }

  const recurrenaceMeeting = {};

  switch(state.tab) {
    case 0:
      let days = "";
      if(state.day_1) {
        days+='1,'
      }
      if(state.day_2) {
        days+='2,'
      }
      if(state.day_3) {
        days+='3,'
      }
      if(state.day_4) {
        days+='4,'
      }
      if(state.day_5) {
        days+='5,'
      }
      if(state.day_6) {
        days+='6,'
      }
      if(state.day_7) {
        days+='7,'
      }
      recurrenaceMeeting['type'] = 2
      recurrenaceMeeting['weekly_days'] = days.substring(0, days.length-1);
      break
    case 1:
      recurrenaceMeeting['type'] = 3
      recurrenaceMeeting['monthly_week'] = state.week
      recurrenaceMeeting['monthly_week_day'] = state.week_day
      break;
    case 2:
      recurrenaceMeeting['type'] = 3
      recurrenaceMeeting['repeat_interval'] = 1
      recurrenaceMeeting['monthly_day'] = getDayOfMonth(state.date_month)
      break;
  }

  recurrenaceMeeting['end_date_time'] = dateToServerFormat(state.date_end, state.time_end);
  console.log('recurrenace', recurrenaceMeeting)
  return recurrenaceMeeting;
}

function ZoomMeetings() {

  if(!isAuthenticated()) {
    return <Redirect to='/authentication/sign-in'  />
  }


  const history = useHistory();

  const [title, setTitle] = useState('')
  const [agenda, setAgenda] = useState('')
  const [date, setDate] = useState(getDateNow())
  const [time, setTime] = useState(getTimeNow())
  const [duration, setDuration] = useState('')
  const [selectedOption, setSelectedOption] = useState([]);
  const [loadUsers, setLoadUsers] = useState(true);
  const [members, setMembers] = useState([]);

  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [progress, setProgress] = useState('');
  const [defaultUser, setDefaultUser] = useState([]);
  const [openRecurrence, setOpenRecurrence] = useState(false);
  const [recurrenaceType, setRecurrenaceType] = useState('None');

  const recurrenaceData = useRef(null);
  
  const multiEmailRef = useRef(null);
  
  const currentUser = useRef(getUser());

  const [focused, setFocused] = useState(false);
  const [emails, setEmails] = useState([]);

  const recurrenaceState = useRef({
    tab: 0,
    day_1: true,
    day_2: true,
    day_3: true,
    day_4: true,
    day_5: true,
    day_6: true,
    day_7: true,
    week : '1',
    week_day : '2',
    date_month: getDateNow(),
    date_end: getDateAfterMonth(3),
    time_end: '11:00:00'
  })

  const email = getUserEmail();

  const styles = {
    control: base => ({
      ...base,
      fontSize: "14px"
    }),
    menu: base => ({
      ...base,
      fontSize: "14px"
    })
  };

  const loadMembers = async () => {
    // console.log('loadMembers');
    apiCallSecureGet(membersApi,
    (response) => {
        setLoadUsers(false);
        const users = []
        response.list.map((user) => {
          users.push(
              {
                value: user.accountId,  label: `${user.first_name} ${user.last_name}`
              }
            )
          }
        );  
        // setDefaultUser(users[0])
        console.log('current user', currentUser.current)
        setDefaultUser(
          [
            {
              value: currentUser.current.id,  label: `${currentUser.current.firstName} ${currentUser.current.lastName}`
            }
          ]
        )
   
        setMembers(users)

    },
    (errorMsg) => {
        setLoadUsers(false);
        showError(errorMsg||'Error');
        // console.log('ui error', errorMsg||'Error');
      }
    )
  }

  useEffect(() => {loadMembers();}, [loadUsers])

  useEffect(() => {
    console.log('selectedOption', selectedOption);
  }, [selectedOption])
  useEffect(() => {
    console.log('defaultUser', defaultUser);
  }, [defaultUser])

  const animatedComponents = makeAnimated();

  function showError(msg) {
    setError(msg);
    setTimeout( () => {setError('')}, 3000);
  }

  function showMessage(msg) {
    setMessage(msg);
    setTimeout( () => {setMessage('')}, 3000);
  }


  const onSaveRecurrence = (tab, state) => {
      state.current['tab'] = tab
      console.log('onSaveRecurrence 1', state.current);
      recurrenaceData.current = state.current
      
      console.log('onSaveRecurrence 2', recurrenaceData.current);

      switch(recurrenaceData.current.tab) {
        case 0:
          setRecurrenaceType('Daily');
          break
        case 1:
          setRecurrenaceType('Weekly');
          break;
        case 2:
          setRecurrenaceType('Monthly');
          break;
      }
  }

  const onError = (msg) => {
    showMessage(msg);
  }

  const timezoneOffset = getTimeZone();

  // console.log('TZ > ',timezoneOffset);

  const resetRecurrence = () => {
            recurrenaceData.current = null;
            recurrenaceState.current = {
              tab: 0,
              day_1: true,
              day_2: true,
              day_3: true,
              day_4: true,
              day_5: true,
              day_6: true,
              day_7: true,
              week : '1',
              week_day : '2',
              date_month: getDateNow(),
              date_end: getDateAfterMonth(3),
              time_end: '11:00:00'
            }
            setRecurrenaceType('None');
  }

  const createMeeting = () => {

    
    
    // console.log('create meeting', selectedOption.length);

    if(selectedOption.length < 1) {
      showError('Please selec members for this meeting')
      return
    }

    const meetingMembers = []


    selectedOption.map((user) =>
      meetingMembers.push(user.value)
    );  


    if(title.trim() === "") {
      showError('Enter meeting title')
      return
    }

    if(agenda.trim() === "") {
      showError('Enter meeting agenda')
      return
    }

    if(duration.trim() === "") {
      showError('Enter meeting duration in minutes')
      return
    }

    if(date.trim() === "") {
      showError('Enter meeting date')
      return
    }

    if(time.trim() === "") {
      showError('Enter meeting time')
      return
    }


    const email = getUserEmail();

    const tz = moment.tz.guess();

    console.log('tz', tz);
    console.log('getRecurrenceMeeting', recurrenaceData.current)
    let type = 2;
    let recurrenaceMeetingData = null;

    if(recurrenaceData.current != null) {
      console.log('RECURRENCE');
      type = 8;
      recurrenaceMeetingData = getRecurrenceMeeting(recurrenaceData.current);
    }

    const body = {
        'username': email,
        'action': 'add',
        'topic': title, 
        'type': type,
        'start_time': dateToServerFormat(date, time),
        'duration': duration,
        'schedule_for': email,
        'timezone': "UTC",
        'password': generatePassword(8),
        'agenda': agenda,
        'members': meetingMembers,
        'recurrence': recurrenaceMeetingData,
        'invites': emails
    }

    console.log('body', body);

    setProgress('Creating Meeting')

    apiPostSecure(meetingsApi, body, 
      (response) => {
        setProgress('');
          if(response.code === 200) {
            setTitle('');
            setAgenda('');
            setDate('');
            setTime('');
            setDuration('');
            resetRecurrence();
            showMessage('Meeting is created successfully');
            setEmails([])
            return
          }
          showError(response.data.message)
      },
      (err) => {
        console.log('Create meeting Error: ', err);
        setProgress('');
        showError(err)
      })
  }

  // console.log('getTimeNow', date, time);
  // setTime(timeNow)

  // console.log('members', members.length);

  function onBack() {
    // console.log('on back');
    history.goBack();
  }

  const ClearRecurrence = () => {
    if(recurrenaceType == 'None') {
      return null;
    }
    return (<SuiButton variant="contained"  color='gradient' onClick={() => {resetRecurrence()}}>X</SuiButton>)
  }

  const addView = (
    <Card className="h-100">
          <SuiBox pt={2} px={2}>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center">
              <SuiBox display="flex" flexDirection="row">
                <Button variant="text" fontWeight="medium" textColor="text" onClick={() => onBack()}>
                  <ArrowBack color="secondary" />
                </Button>
                <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Create Meeting
                </SuiTypography>
              </SuiBox>
            </SuiBox>
            <SuiBox opacity={0.3}>
              <Divider />
            </SuiBox>
            <Grid container spacing={6}>
            <Grid item xs={12} md={6} xl={6}>
                <SuiBox >
                  <SuiBox  ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Add Members in this meeting 
                    </SuiTypography>
                    <Select
                        styles={styles}
                        onChange={setSelectedOption}
                        placeholder='Search or select members'
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        options={members}
                        defaultValue={defaultUser}
                        isMulti
                      />
                  </SuiBox>
                </SuiBox>
              </Grid>   
              <Grid item  xs={12} md={6} xl={6}>
                <SuiBox  >
                  <SuiBox ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Topic
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput type="text" placeholder="Topic" value={title} onChange={(e) => setTitle(e.target.value)} />
                </SuiBox>
              </Grid>
              <Grid item xs={12} md={6} xl={6}>
                <SuiBox >
                  <SuiBox  ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Agenda
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput type="text" placeholder="Agenda" value={agenda} onChange={(e) => setAgenda(e.target.value)} />
                </SuiBox>          
              </Grid>
              <Grid item xs={12} md={6} xl={6}>
                <SuiBox >
                  <SuiBox  ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Invite People
                    </SuiTypography>
                  </SuiBox>
                  
                  <ReactMultiEmail
                    ref={multiEmailRef}
                    placeholder="Enter email addresses to invite in meeting"
                    emails={emails}
                    onChange={(emails) => {
                      setEmails(emails);
                    }}
                    getLabel={(
                      email,
                      index,
                      removeEmail
                    ) => {
                      return (
                        <div data-tag key={index}>
                          {email}
                          <span data-tag-handle onClick={() => removeEmail(index)}>
                            ×
                          </span>
                        </div>
                      );
                    }}
                  />

                </SuiBox>          
              </Grid>
              <Grid item xs={12} md={3} xl={3}>
                <SuiBox >
                  <SuiBox  ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Duration in minutes
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput type="text" pattern="[0-9]*" placeholder="Duration" value={duration} onChange={(e) => setDuration(e.target.value)} />
                </SuiBox>          
              </Grid>
              <Grid item xs={12} md={3} xl={3}>
                <SuiBox >
                  <SuiBox ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Date
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput type="date" placeholder="Date" value={date} onChange={(e) => setDate(e.target.value)} />
                </SuiBox>          
              </Grid>
              <Grid item xs={12} md={3} xl={3}>
                <SuiBox >
                  <SuiBox  ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Time 
                    </SuiTypography>
                  </SuiBox>
                  <SuiInput type="time" placeholder="Time" value={time} onChange={(e) => setTime(e.target.value)} />
                </SuiBox>
              </Grid>
              <Grid item xs={12} md={3} xl={3}>
                <SuiBox >
                  <SuiBox  ml={0.5}>
                    <SuiTypography component="label" variant="caption" fontWeight="bold">
                      Recurrence
                    </SuiTypography>
                  </SuiBox>
                  <Stack  direction="row" spacing={2}>
                    <SuiButton sx={{width: '80%'}} variant="contained"  color='info' onClick={() => {setOpenRecurrence(true)}}>{recurrenaceType}</SuiButton>
                    <ClearRecurrence />
                  </Stack>
                </SuiBox>
              </Grid>  
            </Grid>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" py={3}>
              <SuiBox display="flex" flexDirection="row" >
                <SuiButton variant="gradient" buttonColor="secondary"  onClick={() => onBack()} >
                  cancel
                </SuiButton>
                <SuiBox pl={2}>
                  <SuiButton variant="gradient" buttonColor="primary"  onClick={() => createMeeting()}  p={2} >
                    Create Meeting
                  </SuiButton>
                </SuiBox>
              </SuiBox>
          </SuiBox>
        </SuiBox>
      </Card>
      )
    
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          {showSuccessAlert(message)}
          {showErrorAlert(error)}
          {progressDialog(progress)}
          {addView}
          <RecurrenceModal show={openRecurrence} change={setOpenRecurrence} state={recurrenaceState} onSave={onSaveRecurrence}/>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ZoomMeetings;

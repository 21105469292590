import * as React from 'react';
import { Navigate, useNavigate, Link as RouterLink } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import QrCodeIcon from '@mui/icons-material/QrCode';
import InfoIcon from '@mui/icons-material/Info';

import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

import Delete from '@mui/icons-material/Delete';
import Visibility from '@mui/icons-material/Visibility';
import { Tooltip, IconButton, Stack, Avatar } from '@mui/material';
import {isAdmin} from '../../../utils/session'
import {dateToShowFormat, isBeforeToday} from "utils/ext"

import { green, pink, blue, white } from '@mui/material/colors';
import {roleId} from '../../../utils/session'

const getDescription = (desc) => {
  if(desc) {
    if(desc.length > 45) {
      return `${desc.substring(0, 45)}...`
    }
    return desc;
  }
  return '------'
}

const getMeetingName = (name) => {
  if(name) {
    if(name.length > 25) {
      return `${name.substring(0, 25)}...`
    }
    return name;
  }
  return '------'
}

export default function MeetingCard({meeting, onViewQr, onDetails, onDelete}) {

  if(!meeting) {
    return null;
  }

  const GetActions = () => {
    if(isAdmin()) {
      return (
        <Stack 
          sx={{width:'100%'}}
          justifyContent="right"
          alignItems="right"
          direction="row"> 
          <Tooltip title="Show QR code" sx={{mx: 1}}>
            <Avatar sx={{ bgcolor: '#31BAFF' }}>
              <IconButton sx={{ color: '#fff' }}  variant="contained" size="small" onClick={() => onViewQr(meeting)}>
                <QrCodeIcon />
              </IconButton>
            </Avatar>        
          </Tooltip>
          <Tooltip title="Details" sx={{mx: 1}}>
            <Avatar  sx={{ mx: 2, bgcolor: '#31BAFF' }}>
              <IconButton sx={{ color: '#fff' }}  variant="contained" size="small" onClick={() => onDetails(meeting)}>
                <InfoIcon />
              </IconButton>
            </Avatar>        
          </Tooltip>
          <Tooltip title="Delete" sx={{mx: 1}}>
            <Avatar sx={{ bgcolor: '#FF3131' }}>
              <IconButton sx={{ color: '#fff' }}  variant="contained" size="small" onClick={() => onDelete(meeting)}>
                <Delete />
              </IconButton>
            </Avatar>            
          </Tooltip>
        </Stack>
      )
    }
    return (
      <Stack 
      sx={{width:'100%'}}
      justifyContent="center"
      alignItems="center"
      direction="row"> 
      <Tooltip title="Show QR code" sx={{mx: 1}}>
          <Avatar sx={{ bgcolor: '#00D606' }}>
            <IconButton sx={{ color: '#fff' }}  variant="contained" size="small" onClick={() => onViewQr(meeting)}>
              <QrCodeIcon />
            </IconButton>
          </Avatar>        
        </Tooltip>
        <Tooltip title="Details" sx={{mx: 1}}>
          <Avatar  sx={{ mx: 2, bgcolor: '#31BAFF' }}>
            <IconButton sx={{ color: '#fff' }}  variant="contained" size="small" onClick={() => onDetails(meeting)}>
              <InfoIcon />
            </IconButton>
          </Avatar>        
        </Tooltip>
      </Stack>
    )
  }

  return (
    <Card  sx={{ boxShadow: 1, m: 2, height: '240px' }} spacing={2}>
      <CardContent> 
      <Stack 
          sx={{width:'100%'}}
          justifyContent="right"
          alignItems="right"
          direction="row"> 
          <SuiBox>
            <SuiTypography color='dark' fontWeight='bold' variant="h5" gutterBottom component="div">
            {dateToShowFormat(meeting.start_time, meeting.timezone)}
            </SuiTypography>
            <SuiTypography color='dark' fontWeight='regular' gutterBottom variant="h6" component="div">
              {getMeetingName(meeting.topic)}
            </SuiTypography>
            <SuiTypography color='dark' fontWeight='regular' variant="h6" >
              {getDescription(meeting.agenda)}
            </SuiTypography>
            <SuiTypography sx={{mt: 1}} color='dark' fontWeight='regular' variant="h6">
              ID: {meeting.id}
            </SuiTypography>
            <SuiTypography color='dark' fontWeight='regular' variant="h6" >
              Duration: {meeting.duration} Mins
            </SuiTypography>
          </SuiBox>
          <GetActions />
        </Stack>

      </CardContent>
      <CardActions sx={{rightAlignItem: {marginLeft: "auto"}}}>
       
      </CardActions>
    </Card>
  );
}
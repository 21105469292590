
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import imgMeetingList from "assets/screens/meeting-list.png";
import imgMeetingCreate from "assets/screens/meeting-create.png";
import imgDashboard from "assets/screens/dashboard.png";
import imgUserList from "assets/screens/user-list.png";
import imgZoomConnect from "assets/screens/zoom-connect.png";
import appLogin from "assets/screens/app/login.png";
import appHome from "assets/screens/app/meetings.png";
import appMeetingList from "assets/screens/app/meeting-list.png";
import appScan from "assets/screens/app/scan-qr.png";
import appMeetingStart from "assets/screens/app/meeting-start.png";
import imgDisconnect from "assets/screens/zoom-disconnect.png";

import screen1 from "assets/screens/screen_1.png";
import screen2 from "assets/screens/screen_2.png";
import screen3 from "assets/screens/screen_3.png";
import screen4 from "assets/screens/screen_4.png";
import screen5 from "assets/screens/screen_5.png";
import screen6 from "assets/screens/screen_6.png";
import screen7 from "assets/screens/screen_7.png";
import screen8 from "assets/screens/screen_8.png";
import screen9 from "assets/screens/screen_9.png";
import screen10 from "assets/screens/screen_10.png";
import screen11 from "assets/screens/screen_11.png";
import screen12 from "assets/screens/screen_12.png";
import screen13 from "assets/screens/screen_13.png";
import screen14 from "assets/screens/screen_14.png";
import screen15 from "assets/screens/screen_15.png";
import screen16 from "assets/screens/screen_16.png";
import screen17 from "assets/screens/screen_17.png";
import { Box } from "@mui/material";

const imgWidth = 700

export const content = () => {
    

    return (
        <Card className="h-100">
              <Box sx={{width: {imgWidth}, px: 2, py: 2}}>

                <SuiTypography mt={2} variant="h6" fontWeight="medium" textTransform="capitalize">
                  Log in to ZCONNECT
                </SuiTypography>
                <SuiTypography  ml={3} align='justify' fontWeight='light' variant='h6' >
                  <img
                      width={imgWidth}
                      src={screen2}
                      alt="Zconnect"
                    />
                </SuiTypography>
    
                <SuiTypography mt={2} variant="h6" fontWeight="medium" textTransform="capitalize">
                  Connect ZCONNECT account to Google Glass
                </SuiTypography>
                <SuiTypography   ml={3} align='justify' fontWeight='light' variant='h6' >
                  
                  <ol  style={{width: imgWidth}}>
                    <li>
                      <p>Click on the Dashboard, there is a QR code to be scanned to connect with your Google Glass.</p>
                      <img
                        width={imgWidth}
                        src={screen3}
                        alt="Zconnect"
                      />
                    </li>                
                  </ol>
                </SuiTypography>

                <SuiTypography mt={2} variant="h6" fontWeight="medium" textTransform="capitalize">
                  Setup Meeting
                </SuiTypography>
                <SuiTypography   ml={3} align='justify' fontWeight='light' variant='h6' >
                      <img
                          width={imgWidth}
                          src={screen4}
                          alt="Zconnect"
                        />
                      <ol  style={{width: imgWidth}}>
                      <li>Click on “My meeting”.</li>                
                      <li>
                        <p>Click on “+ Create Meeting” to create a meeting.</p>                
                        <img
                          width={imgWidth}
                          src={screen5}
                          alt="Zconnect"
                        />
                      </li>                
                      <li>Select Members.</li>                
                      <li>Input Topic, Agenda, Duration in minutes, Date, Time.</li>                
                      <li>
                        <p>Click on Recurrence to schedule a recurring meeting.</p>  
                        <img
                          width={imgWidth}
                          src={screen6}
                          alt="Zconnect"
                        />            
                      </li>  
                      <li>
                        <p>The schedule meeting has setup.</p>  
                        <img
                          width={imgWidth}
                          src={screen7}
                          alt="Zconnect"
                        />            
                      </li>  
                  </ol>
                </SuiTypography>

                <SuiTypography mt={2} variant="h6" fontWeight="medium" textTransform="capitalize">
                 How to start meetings and Functions for meeting
                </SuiTypography>
                <SuiTypography   ml={3} align='justify' fontWeight='light' variant='h6' >

                    <ol  style={{width: imgWidth}}>
                        <li>
                          <p>Tap into apps from this page</p>    
                          <img
                              width={imgWidth}
                              src={screen8}
                              alt="Zconnect"
                            />
                        </li>

                        <li>
                          <p>Swipe and search for ZCONNECT.</p>    
                          <img
                              width={imgWidth}
                              src={screen9}
                              alt="Zconnect"
                            />
                        </li>

                        <li>
                          <p>Then select “MY MEETING”.</p>   
                          <img
                              width={imgWidth}
                              src={screen10}
                              alt="Zconnect"
                            />
                        </li>

                        <li>
                          <p>Then select on the meeting as shown in this image.</p>   
                          <img
                              width={imgWidth}
                              src={screen11}
                              alt="Zconnect"
                            />
                        </li>

                        <li>
                          <p>You can select Shield icon where you can admit others into the meeting. </p>   
                          <img
                              width={imgWidth}
                              src={screen12}
                              alt="Zconnect"
                            />
                        </li>

                        <li>
                          <p>This is where you tap and allow people to join the meeting.</p>   
                            <img
                                width={imgWidth}
                                src={screen13}
                                alt="Zconnect"
                              />
                          </li>

                          <li>
                            <p>As seen in this image, another person has admitted and joined the meeting</p>   
                            <img
                                width={imgWidth}
                                src={screen14}
                                alt="Zconnect"
                              />
                          </li>

                          <li>
                            <p>In this image the icon highlighted in orange, is to allow users to check who is in the meeting.</p>   
                            <img
                                width={imgWidth}
                                src={screen15}
                                alt="Zconnect"
                              />
                          </li>

                          <li>
                            <p>As seen in this image these are the users currently in the meeting.</p>   
                            <img
                                width={imgWidth}
                                src={screen16}
                                alt="Zconnect"
                              />
                          </li>

                          <li>
                            <p>When the meeting is over, you can leave by tapping/clicking the highlighted icon and leave.</p>   
                            <img
                                width={imgWidth}
                                src={screen17}
                                alt="Zconnect"
                              />
                          </li>

                          <li>
                            <p>You will then be brought back to this page after leaving the meeting.</p>   
                            <img
                                width={imgWidth}
                                src={screen1}
                                alt="Zconnect"
                              />
                          </li>

                      </ol>          
                </SuiTypography>

              </Box>

          </Card>
        )
}

export default content;
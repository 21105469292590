import Card from "@mui/material/Card";
import { useHistory, Redirect, Link } from 'react-router-dom'
import { useState, useEffect, useRef } from "react";


import MUIDataTable from 'mui-datatables';
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Typography from '@mui/material/Typography';
import QrCodeScannerRoundedIcon from '@mui/icons-material/QrCodeScannerRounded';
import TablePagination from '@mui/material/TablePagination';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import {getDateTimeNow} from "utils/ext" 
import {isAuthenticated, isAdmin, getUserEmail} from "utils/session" 
import {Alert, Grid, Box} from "@mui/material";
import {progressDialog, alertDialog, showToastMessage, NewQrDialog, MettingQrDialog, DeleteRecurringMeeting} from "utils/diloag"

// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Table";
import SuiButton from "components/SuiButton";
import Icon from "@material-ui/core/Icon";
import {meetingsApi, usersApi, apiCallSecureGet, apiPostSecure} from "utils/api"
import {dateToTimestamp} from "utils/ext"
import SuiPagination from "components/SuiPagination";

import {getColoumns, getMeetingRows, getRecurringRows} from "./data/meetingsTableData";
import MeetingCard from "./data/MeetingCard";

// Custom styles for the Tables
import styles from "./styles";

const PER_PAGE = 15;

// Data
function getPages(current, count, handlePageChange) {
  if(current == 0) {
    return null;
  }

  return (
    <Stack 
      sx={{width:'100%'}}
      justifyContent="right"
      alignItems="right"
      direction="row"> 
      <Pagination color="secondary" count={count} page={current} onChange={handlePageChange} />
    </Stack>
    );
}

function getAlert(msg) {
  let view = null;

  if(msg) {
    view = (
      <Alert severity="info" >
         {msg}
      </Alert>
    )
  }

  return view;
}

function Tables() {

  if(!isAuthenticated()) {
    return <Redirect to='/authentication/sign-in'  />
  }

  const classes = styles();
  const history = useHistory();

  const [error, setError] = useState('');
  const [progressTitle, setProgressTitle] = useState('');
  const [showAlertMessage, setShowAlertMessage] = useState('');

  const [showToast, setShowToast] = useState(false);
  const [meetingId, setMeetingId] = useState('');

  const [loadMeetings, setLoadMeetings] = useState(true);
  const [createQr, setSetCreateQr] = useState(false);

  const [showToastAlert, setShowToastAlert] = useState(false);

  showToastAlert

  const [meetingQr, setMeetingQr] = useState(null);

  const [meetings, setMeetings] = useState([]);
  
  const tostAlertMessage = useRef(null)
  const deleteMeeting = useRef(null)
  const allMeetings = useRef(null)
  const totalPages = useRef(0)

  const [showDeleteRecurring, setShowDeleteRecurring] = useState(false);

  const [pages, setPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const setCreateMeeting = () => {
      history.push('/create-meeting');
  }

  const handleClose = (event, reason) => {
    
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
  };


  function onDetails(meeting) {

    if(meeting.type == 8) {
      history.push({
        pathname: '/meeting-info',
        state: { 
          meetingId: meeting.id, 
          date: meeting.start_time, 
          index: meeting.index, 
          type: meeting.type, 
          duration: meeting.duration, 
        },
      });
      return;
    }
    history.push({
        pathname: '/meeting-info',
        state: { 
          meetingId: meeting.id, 
          date: '', 
          index: 0, 
          type: meeting.type, 
          duration: 0, 
        },
      });

  }

  function onViewQr(meeting) {
      setMeetingQr(meeting);
  }

  function onDelete(meeting) {

    if(meeting.type == 8) {
      deleteMeeting.current = meeting
      setShowDeleteRecurring(true)
      return
    }

    setMeetingId(meeting.id);
    setShowAlertMessage(`Do you want to delete ${meeting.topic} meeting?`)
  }

  const onDeleteSingle = () => {
      setShowDeleteRecurring(false);
      console.log('onDeleteSingle >> ', deleteMeeting.current);
      const data = {
        'action': 'delete',
        'meeting': deleteMeeting.current.id,
        'occurrence': deleteMeeting.current.occurrence,
        'index': deleteMeeting.current.index,
      }
      deleteMeetingByIds(data);
  }


  const onDeleteAll = () => {
    setShowDeleteRecurring(false);
    console.log('onDeleteAll >> ', deleteMeeting.current);
    const data = {
      'action': 'delete',
      'meeting': deleteMeeting.current.id,
    }

    deleteMeetingByIds(data);
  }

  async function deleteMeetingByIds(data) {

    console.log(`deleteMeetingByIds`, data);
    setProgressTitle('Deleting Meeting')
    apiPostSecure(meetingsApi,  data,
      (response) => {
        console.log(`deleteMeetingByIds SUCCESS`, response);
        setError(response.data.message)
        setLoadMeetings(true);
      },
      (errorMsg) => {
        console.log(`deleteMeetingByIds ERROR`, errorMsg);
        setProgressTitle('')
        setError(errorMsg.msg)
      }
      )
  }


  function onAlertOk() {
    setShowAlertMessage('')
    
    const data = {
      'action': 'delete',
      'meeting': meetingId
    }

    // console.log(`OK ${meetingId}`);
    setProgressTitle('Deleting Meeting')
    apiPostSecure(meetingsApi,  data,
      (response) => {
        setProgressTitle('')
        if(response.data.code === 204){
          setTimeout(setLoadMeetings(true), 3000);
        }
        setError(response.data.message)
      },
      (errorMsg) => {
        setProgressTitle('')
        setError(errorMsg.msg)
      }
      )

  }

  function onAlertCancel() {
    setShowAlertMessage('')
    // console.log('onAlertCancel');
  }

  const onPrevPage = () => {
    console.log('onPrevPage');
    let pageNo = currentPage.current - 1;
    if(pageNo > 0) {
      setCurrentPage(pageNo);
    }
  }
  
  const onNextPage = () => {
    let pageNo = currentPage.current + 1;
    console.log('onNextPage', pageNo);
    setCurrentPage(pageNo);
  }
  
  const onPageClick = (page) => {
    console.log('onPageClick', page);
    setCurrentPage(page);
  }

  async function loadMeetingData(data) {

    if(!data) {
      setError('There is no available meeting!');
      return
    }

    const rows = [];

    const timeNow = new Date().getTime();

    if(data.meetings) {
        if(data.meetings.length > 0) {
          data.meetings.map((meeting) => {
            rows.push(
              {
                id: meeting.id,
                start_time: meeting.start_time,
                timezone: meeting.timezone,
                duration: meeting.duration,
                topic: meeting.topic,
                agenda: meeting.agenda,
                join_url: meeting.join_url,
                type: meeting.type,
                timestamp: dateToTimestamp(meeting.start_time, meeting.timezone)
              }
            )
          })
      }        
    }
    if(data.recurrence) {
      if(data.recurrence.length > 0) {
        data.recurrence.map((meeting) => {
          meeting.occurrences.map((occurrence, index) => {
            const timestamp = dateToTimestamp(occurrence.start_time, meeting.timezone);
            console.log('timeNow', timeNow, timestamp, timeNow <= timestamp);
            if (timeNow <= timestamp) {
              rows.push(
                {
                  id: meeting.id,
                  start_time: occurrence.start_time,
                  timezone: meeting.timezone,
                  duration: occurrence.duration,
                  occurrence: occurrence.occurrence_id,
                  topic: meeting.topic,
                  agenda: meeting.agenda,
                  join_url: meeting.join_url,
                  type: meeting.type,
                  index: index,
                  timestamp: timestamp
                }
              )
          }
          })
        })
      }
    }

    rows.sort(function(a, b) {
      if (a.timestamp < b.timestamp) return -1;
      if (a.timestamp > b.timestamp) return 1;
      return 0;
    });

    console.log(rows);

    allMeetings.current = rows;
    console.log('meetings', rows.length, allMeetings.current?.length);
   
    if(rows.length > 0) {
      const pages = rows.length / PER_PAGE;
      totalPages.current = Math.ceil(pages);
      console.log('pages', pages, totalPages.current, rows.length);
      setPages(getPages(1, totalPages.current, handlePageChange));
    }else {
      setPages(null)
      setError('There is no available meeting!');
    }

    setProgressTitle("")
    console.log('loadPage', currentPage)
    if (currentPage == 0) {
      setCurrentPage(1);
    }else {
      loadPage();
    }
  }

  const loadPage = () => {
    console.log('REFRESH PAGE', currentPage);
    
    const meetingsList = allMeetings.current;

    if(meetingsList) {

      if(meetingsList.length == 0) {
        setMeetings(null);
        setPages(null);
        return
      }

      const newMeetings = [];

      const start = (currentPage - 1) * PER_PAGE;
      let end = start + PER_PAGE;

      if(end >= meetingsList.length) {
        end = meetingsList.length
      }

      console.log('meetingsList', meetingsList.length);
      console.log('newMeetings', currentPage, start, end);
      for (let i = start; i <= end; i++) {
        newMeetings.push(meetingsList[i]);
      }
  
      const itemList = newMeetings.map((item) => (
        <Grid item xs={12} md={6} lg={6}>
            <MeetingCard meeting={item} onDetails={onDetails}  onDelete={onDelete} onViewQr={onViewQr} />
        </Grid>
      ));
      setMeetings(itemList)

      setPages(getPages(currentPage, totalPages.current, handlePageChange));
      console.log('current', newMeetings);
      return
    }

    setMeetings(null);
    setPages(null);
  }

  useEffect(() => {
    loadPage()
  }, [currentPage])

  useEffect(() => {
    setTimeout(setError(null), 5000);
  }, [error])


  const loadZoomMeetings = async () => {
    console.log('>>loadZoomMeetings<<', loadMeetings);
    if(!loadMeetings) {
      return
    }
    setLoadMeetings(false);
    setProgressTitle("Loading meetings!")
    apiCallSecureGet(`${meetingsApi}?email=${getUserEmail()}&date=${getDateTimeNow()}`,
    (response) => {
        console.log('response', response);
        loadMeetingData(response.data);
    },
    (errorMsg) => {
      setProgressTitle("")
        // console.log('error >>> ', errorMsg);
        setError('Something went wrong!');
        setInterval( () => {setError('')}, 3000);
        // console.log('ui error', errorMsg||'Error');
      }
    )
}

const CreateButton = () => {
  if(isAdmin()) {
    return (
      <SuiButton sx={{mx:2}} variant="gradient" buttonColor="dark"  onClick={() => setCreateMeeting()}>
          <Icon className="material-icons-round font-bold">add</Icon>
          &nbsp;Create Meeting
      </SuiButton>
    )
  }
  return null
}

useEffect(() => {
  console.log('loadMeetings', loadMeetings)
  if(loadMeetings) {
    loadZoomMeetings();
  }
}, [loadMeetings])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <SuiBox mb={3}>
            <SuiTypography variant='body2'>
              You can add members in the meeting and create Zoom meeting through ZCONNECT
            </SuiTypography>
          </SuiBox>
          {showToastMessage(showToastAlert, "info", tostAlertMessage?.current, handleClose)}
          {showToastMessage(showToast, "success", "Meeting link copied!", handleClose)}
          {getAlert(error)}
          {progressDialog(progressTitle)}
          {alertDialog(true, "Delete Meeting?", showAlertMessage, onAlertOk, onAlertCancel)}
          <MettingQrDialog meeting={meetingQr} setMeeting={setMeetingQr} />
          <NewQrDialog show={createQr} setShow={setSetCreateQr} />
          <DeleteRecurringMeeting show={showDeleteRecurring} setShow={setShowDeleteRecurring} meeting={deleteMeeting} onDeleteAll={onDeleteAll} onDeleteSingle={onDeleteSingle} />
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">My meeting</SuiTypography>
              <SuiBox>
                <SuiButton variant="gradient"   onClick={() => setSetCreateQr(true)}>
                    <Icon className="material-icons-round font-bold"><QrCodeScannerRoundedIcon/></Icon>
                    &nbsp;Create QR Code
                </SuiButton>
                <CreateButton />
              </SuiBox>
            </SuiBox>
          </Card>         
        </SuiBox>
      </SuiBox>
        {/* <MUIDataTable title={'Meetings'} data={meetings} columns={columns} options={options} /> */}
        <SuiBox m={3}>
          {pages}
        </SuiBox>
        <Grid container spacing={2}>
          {meetings}
        </Grid>
        <SuiBox m={3}>
          {pages}
        </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
